import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h1 color="grey">HELLO. WELCOME TO MY WORLD OF AWESOMENESS!!!</h1>
    </div>
  );
}

export default App;
